import * as React from "react"
import { PageNavbar } from '../components'
import '../styles/layouts/main-layout.scss'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Helmet from 'react-helmet'

const theme = createMuiTheme({
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    typography: {
        fontSize:14,
        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),
        h6: {
            fontSize: '0.9rem',
            padding: '0.7rem 2rem',
            cursor: 'pointer',
            fontWeight: 500,

            
        }
    },
    palette: {
        primary: {
            main: 'hsl(202deg 58% 19% / 75%)',
            contrastText: '#fff',
        },
        secondary: {
            main: 'hsl(177deg 67% 26%)',
            contrastText: '#fff',
        }
    },
    '@global': {
        '@keyframes spin': {
            '100%:': {
              transform: 'rotateY(360deg) rotateX(360deg)'
            }
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'capitalize',
                backgroundColor: '#131f37',
                border: '0px solid #131f37',
                borderRadius: '5px',
                transition: 'background-color 500ms linear',

                '&:hover': {
                    backgroundColor: '#0FC1B7',
                }
                
            },
            textPrimary: {
                color: '#fff',
            },
            textSecondary: {
                color: '#fff',
            }
        },
    }
});

const MainLayout = ( { children } ) => {
    return (
        <ThemeProvider theme={theme}>
            <Helmet
                bodyAttributes={{
                    class: 'bg--main'
                }}
            >
            </Helmet>
            <div className='rel w100 h100'>
                <PageNavbar />
                {
                    children
                }
            </div>
        </ThemeProvider>
    )
}

export default MainLayout;